/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:host {
  --placeholder-opacity: 1;
  --color-checked: #000000 ;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ion-page {
  contain: layout size style;
}

html {
  text-size-adjust: 100%;
}

body {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

:root {
  /** primary **/
  --ion-background-color: rgb(255, 255, 255);
  /* --ion-font-family: 'Lexend'; */
  --ion-color-primary: #2575fc;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: linear-gradient(
    to right,
    #6a11cb 0%,
    #2575fc 100%
  );
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #1ede65;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-label: #8e8e93;

  /**light main colors**/
  --ion-color-border: #8e8e93;
  --ion-color-border2: #f2f2f7;
  --ion-color-text-light: #8e8e93;
  --ion-color-text-light2: #ffffff;
  --ion-color-light-blue: #007aff;
  --ion-color-ligt-green: #34c759;
  --ion-color-step-950: #000000;
  --ion-color-light-red: #da291c;
  --ion-color-light-label: #8e8e93;
  --ion-color-light-input: #e5e5ea;
  --ion-color-icon-main: #373737;
  --indicator-color: #da291c;
  --rec-back: linear-gradient(342deg, #a3a7ff 0%, #4349ff 100%);
  --mainpagebuttons: #000000;
  --mainpagebuttonsbg: #f9f9fb;
  --mapsfe1: #e8f7fe;
  --mapsfe2: #f0f3fe;
  --mainblac: rgb(177, 177, 177);
  --ion-card-background: #f9f9fb;
  --mesd: #6d7fbb;
}

.ios body.dark {
  --ion-background-color: #080b0f;
  /* --ion-background-color-rgb: 18, 18, 18; */
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #080b0f;

  --ion-toolbar-background: #080b0f;
  --mainpagebuttons: #dfdfdf;
  --mainpagebuttonsbg: #080b0f;
  --mainblac: #181818;
  --ion-tab-bar-background: #080b0f;
  --ion-color-border2: #080b0f;
  --ion-card-background: #080b0f;
  --rec-back: linear-gradient(342deg, #030113 0%, #15187b 100%);
  --mapsfe1: #d1d9fc67;
  --mapsfe2: #d7cef733;
  --mesd: #1c274c;
}

.md body.dark {
  --ion-background-color: #080b0f;
  /* --ion-background-color-rgb: 18, 18, 18; */
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #080b0f;

  --ion-toolbar-background: #080b0f;
  --mainpagebuttons: #dfdfdf;
  --mainpagebuttonsbg: #080b0f;
  --mainblac: #181818;
  --ion-tab-bar-background: #080b0f;
  --ion-color-border2: #080b0f;
  --ion-card-background: #080b0f;
  --rec-back: linear-gradient(342deg, #030113 0%, #15187b 100%);
  --mapsfe1: #d1e7fc33;
  --mapsfe2: #d7cef733;
  --mesd: #1c274c;
}

.list {
  color: var(--indicator-color);
  background: none;
}

h3 {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 25px !important;
  line-height: 32px !important;
}

h4 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 26px !important;
}

h6 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}

p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}

/* user */

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 10px;
  gap: 0px;

  align-content: center;
}

.user-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  background: var(--ion-color-border2);
  box-shadow: 0px 0px 0.5px rgb(66 71 76 / 32%), 0px 0px 8px rgb(66 71 76 / 5%);
  border-radius: 23px;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.pp {
  /*display: none;*/
  width: 120px;
  height: 100%;
  border-radius: 18px;
  background: url(https://svurguns.com/Data/api/3x4/scan_65e790c5777037.70195643.png);
}

.user-icon {
  font-size: 24px !important;
}

.user-p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--ion-color-step-950) !important;
  padding-left: 8px;
}

.list {
  color: var(--ion-color-icon-main);
}

.version {
  text-align: center;
}

.center {
  text-align: center;
  align-items: center;
}

.header-h {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: var(--ion-color-step-950);
}

.input-area {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

/* plecholder color and opasity*/
ion-select::part(placeholder) {
  color: var(--ion-color-light-label);
  opacity: 1;
  --border-color: none !important;
  --border-style: none;
  --border-width: none;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
}

.item-interactive {
  --border-width: 0 0 0 0 !important;
  --inner-border-width: 0;
  --show-full-highlight: 0;
  --show-inset-highlight: 0;
}

ion-select {
  color: var(--ion-color-light-label);
  opacity: 1;
  --border-color: none !important;
  --border-style: none;
  --border-width: none;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
}

ion-select::part(placeholder) {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

ion-button {
  /* width: 100%; */
  height: 50px;
  --border-radius: 13px;
  z-index: 1;
  --ripple-color: rgb(24, 0, 161);
  box-shadow: none !important;
  --padding-top: 16px;
  --padding-bottom: 16px;
}

.rzvbutton {
  width: 92%;
  height: 50px;
  --border-radius: 13px;
  position: fixed;
  bottom: 20px;
  z-index: 1;
  --ripple-color: rgb(24, 0, 161);
  box-shadow: none !important;
  --padding-top: 16px;
  --padding-bottom: 16px;
}

.flec {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.grgews {
  display: flex;
  padding: 8px 8px;
  align-items: center;
  text-align: center;
  gap: 4px;
  border-radius: 8px;
  background-color: #82cb55;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.whhsd {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.sfvc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.user-icon {
  font-size: 24px !important;
  color: var(--ion-color-text-light);
}

.user-p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--ion-color-text-light) !important;
  padding-left: 8px;
}

.list {
  color: var(--ion-color-step-50);
}

.sffgdj {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.dsfsz {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  /* gap: 18px; */
  flex-wrap: nowrap;
}

.sadf {
  display: flex;
  width: 100%;
  height: 89px;
  padding: 12px;
  flex-direction: column;
  gap: 9px;
  border-radius: 8px;
  font-weight: 600;
  color: var(--mainpagebuttons);
  background: var(--mainpagebuttonsbg);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  align-items: flex-start;
  justify-content: center;
  border: 1px lightblue;
}

.sadfbv {
  display: flex;
  width: 100%;
  height: 270px;
  padding: 12px;
  flex-direction: column;
  gap: 9px;
  border-radius: 8px;
  font-weight: 600;
  /* color: var(--mainpagebuttons); */
  box-shadow: rgba(0, 38, 255, 0.09) 0px 3px 12px;
  align-items: flex-start;
  justify-content: center;
  border: 1px lightblue;
}
.sadfbv .img {
  height: 100%;
}

.profile-img {
  width: 100%;
  height: 100%;
  border: 2px solid #fffb00;
  border-radius: 50%;
}

.profile-imgs {
  display: flex;
  width: 48px;
  height: 48px;
}

.tabbarmains {
  display: flex;
  height: 48px;
  padding: 0px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
}

ion-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  /* background-color: transparent; */
  z-index: -9;
  color: var(--ion-color-step-950);
}

.btn-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

ion-skeleton-text {
  --border-radius: 9999px;
  --background: var(--ion-color-border);
}

.label-inputs {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 21px;
  color: var(--ion-color-light-label);
}

.user-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  border-radius: 16px;
  align-content: center;
  justify-content: center;
  width: max-content;
  color: var(--ion-color-text-light2);
  background: var(--ion-color-light-blue);
  border-radius: 16px;
  margin: 5px 0 0 0;
}

.bvfdsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.container {
  display: flex;
  /* padding: 0px 16px; */
  flex-direction: column;
  align-items: stretch;
  /* margin-right: 30%;
  margin-left: 30%; */
}

@media only screen and (min-width: 600px) {
  .container {
    margin-right: 0;
    margin-left: 0;
  }
  .sabfdkg {
    display: none;
  }
}

.map {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.bg-map {
  position: fixed;
  z-index: -1;
}

.Selected-datetime {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.title-datess {
  display: flex;
  align-items: center;
  gap: 2px;
  color: #39434f;
}

.dates7 {
  display: flex;
  /* width: 327px; */
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.date {
  display: flex;
  width: 45px;
  padding: 10px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--Grey-Grey-150, #eceff2);
  background: var(--Grey-White, #fff);
}

.active {
  border-radius: 10px;
  border: 1px solid var(--Blue-Blue-100, #d1e6ff);
  background: rgb(5, 40, 82);
  color: #fff;
}

.title-dayfd {
  color: var(--Grey-Grey-800, #39434f);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.today {
  border-radius: 14px;
  border: 1px solid var(--Blue-600, #2267b1);
  background: var(--Blue-600, #2267b1);
  color: #fff;
}

.time-cont {
  display: flex;
  /* max-width: 370px; */
  align-items: center;
  align-content: center;
  gap: 12px;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
}

.timesa {
  display: flex;
  min-width: 72px;
  padding: 12px 18px;
  align-items: center;
  align-content: center;
  gap: 12px;
  flex-wrap: wrap;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--Grey-Grey-150, #eceff2);
  background: var(--mainpagebuttonsbg);
  color: var(--mainpagebuttons);
}

.selected {
  border: 1px solid var(--Blue-600, #2267b1);
  background: var(--Blue-600, #2267b1);
  color: #fff;
}

ion-textarea {
  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
  border-radius: 14px;
  border: 1px solid var(--Grey-Grey-200, #d9dfe6);
}

ion-input {
  --padding-bottom: 15px !important;
  --padding-end: 15px !important;
  --padding-start: 15px !important;
  --padding-top: 15px !important;
  border-radius: 10px;
  border: 1px solid var(--Grey-Grey-200, #808080);
}

.ion-input {
  background: var(--ion-item-background);
  color: var(--mainpagebuttons);
  --placeholder-color: #ddd;
  height: 70px;
  min-width: 100%;
  padding: 10px;
  --placeholder-opacity: 0.8;
  border-radius: 10px;
  border: 1px solid var(--Grey-Grey-200, #808080);
}

.label-stacked.sc-ion-label-md-h,
.label-floating.sc-ion-label-md-h {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 8px;
}

.cek-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.cek-1 {
  display: inline-flex;
  /* gap: 21px; */
  width: 333px;
  height: 624px;
  /* padding: 10px; */
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-image: url(https://extrabaku.az/static/media/cek.d1a7509e9ce0d41c2493.png);
}

.chek-sec1 {
  display: flex;
  width: 229px;
  justify-content: space-between;
  align-items: flex-start;
}

.dr1 {
  width: 256.5px;
  height: 1px;
  background-color: var(--mainpagebuttons);
}

.qrcartd {
  width: 99px;
  height: 99px;
}

.sjnds {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 281px;
  justify-content: space-between;
  color: #181818;
  border-bottom: 1px solid #d3d3d3;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 19px;
  padding: 4px;
}

.sjnd {
  margin-top: -10px;
}

.barkode {
  display: flex;
  width: 100% !important;
  height: 100%;
  margin-top: 33px;
}

.container-list {
  /* margin-top: 215px; */
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  padding: 0px 16px;
  color: var(--mainpagebuttons);
  flex-shrink: 0;
}

.listheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--mainpagebuttons);
}

.cssw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* color: #f3f3f3; */
  gap: 12px;
}

.listrezv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  align-self: stretch;
}

.dsfujnh {
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 100%;
  padding: 15px 5px;
  background: var(--ion-color-border2);
  border-radius: 8px;
  overflow: hidden;
  border: 1.5px solid #dcdee6;
}

.time-list {
  color: #da291c;
}

.masa {
  cursor: pointer;
  z-index: 99;
}

.masa :hover {
  color: #007bff !important;
}

.containert {
  padding: 16px;
}

ion-refresher {
  top: 51px;
  padding: 16px;
}

/* ion-router-link {
  width: 100% !important;
} */

.jvfnd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  gap: 30px;
  align-items: center;
}

.ion-content-scroll-host::before,
.ion-content-scroll-host::after {
  position: absolute;

  width: 1px;
  height: 1px;

  content: '';
}

.ion-content-scroll-host::before {
  bottom: -1px;
}

.ion-content-scroll-host::after {
  top: -1px;
}

.disabled {
  background-color: #222428 !important;
  display: block;
}

.jvfnd {
  padding: 6px;
}

.head-list {
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  background-color: #181818;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 48px;
}

.time-list {
  font-size: 14px;
  color: #7f8c8d;
}

.pending-chip {
  margin-top: 5px;
}

.pending-chip ion-label {
  font-size: 16px;
  font-weight: 600;
}

.timemain {
  margin-left: 0;
  color: #a3a3a3;
  font-weight: bold;
  font-size: 12px;
  background: var(--ion-color-border2);
  padding: 5px 13px;
  border-radius: 31px;
}

.svg-mapbg {
  z-index: -1;
  position: fixed;
}

.svg-mapbg2 {
  z-index: 1;
  position: fixed;
}

.ckdj {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infosds {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.loginsd {
  display: flex;
  margin: auto;
  margin-top: 50%;
  margin-bottom: 50%;
  flex-direction: column;
}


.sjter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  gap: 10px;
}

.boximg {
  /* width: 100%; */
  /* max-width: 86px; */
  width: 88px;
  height: 83px;
  margin-right: 12px !important;
  border-radius: 10px;
  overflow: hidden;
  border: solid 2px #007bff;
}

.tjvgnd img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin: 3px;
  overflow: hidden;
}

.tjvgnd {
  /* max-width: 86px;
  width: 88px;
  height: 82px; */
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  z-index: 9999999;
  display: flex;
}

ion-progress-bar {
  --background: #d1d1d1;
  --progress-background: #8d8d8d;
  border-radius: 6px;
}

.imgslidebg {
  width: 100%;
  height: 100vh !important;
}

.imbg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -9;
  filter: blur(50px);
}

.closestorie {
  background: linear-gradient(#0000004a, rgba(253, 253, 253, 0));
  width: 100%;
  padding: 10px 20px;
  /* position: absolute; */
  top: 0;
  z-index: 999999;
  justify-content: flex-end;
  display: flex;
}

.mySwiper {
  background: #000;
}

.dsfujsd {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin: 18px 0;
  border-radius: 20px;
  overflow: hidden;
  background: var(--mainpagebuttonsbg);
  padding: 15px;
  border: 1px solid #c1c1c1;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  justify-content: center;
}

.contetnotyf {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  gap: 14px;
}

.jvfnds {
  visibility: hidden;
  display: none;
  width: 0;
}

.TitleNotf {
  font-weight: 800;
}

.tap-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
  height: 0 !important;
}

.card {
  display: flex;
  padding: 32px 24px;
  background: var(--rec-back);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  margin: 18px 0;
  color: #fff;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  height: 210px;
  width: 100%;
}

.cardbend {
  background: linear-gradient(135deg, #3b1111 0%, #ff0000 100%) !important;
}

.cardbblock {
  background-image: linear-gradient(
    to right,
    #f83600 0%,
    #e5be00 100%
  ) !important;
}

.cardAdmin {
  background: linear-gradient(135deg, #fcdf8a 0%, #f38381 100%) !important;
}

.fjvbn {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
}

.cardjdk {
  display: flex;
  /* gap: 34px; */
  flex-direction: column;
}

.circular-image-container {
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-right: 16px;
}

.circular-image-container img {
  width: 100%;
  height: auto;
}

ion-menu::part(backdrop) {
  background-color: rgba(255, 0, 255, 0.5);
}

ion-menu::part(container) {
  border-radius: 0 20px 20px 0;

  /* box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18); */
}

ion-toolbar {
  --background: var(--ion-background-color);
  --color: var(--ion-color-step-950);

  --min-height: 60px;
  --padding-bottom: 10px;
  --padding-top: 20px;
}

.mapbg {
  background: var(--mainpagebuttonsbg) !important;
  color: var(--mainpagebuttons) !important;
}

.imbgmain {
  width: 100%;
  height: 30vh;
  position: absolute;
  top: 0;
  z-index: -9;
  filter: blur(190px);
  background: url(https://svurguns.com/Data/api/3x4/scan_65e790c5777037.70195643.png);
}

.safgd {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  padding: 15px 25px;
  flex-direction: row;
  justify-content: space-between;
  background: var(--mainpagebuttonsbg);
  border-radius: 16px;
  margin-top: 24px;
}

.fdgxc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fcnjsd {
  background: var(--mainblac);
  border-radius: 5px;
}

.alert-wrapper.sc-ion-alert-md.alert-wrapper.sc-ion-alert-ios {
  --backdrop-opacity: 0.7;
  border-radius: 13px;
}

.circle {
  transform: translate(-50%, -50%);
  background-color: rgba(255, 82, 82, 1);
  border-radius: 50%;
  animation: pulse-red 1.9s infinite;
  height: 10px;
  width: 10px;
  margin-right: 15px;
}

.circle2 {
  position: relative;
  top: -65px;
  /* right: -98px; */
}

@keyframes pulse-red {
  0% {
    transform: scale(0.9) translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  }

  80% {
    transform: scale(1) translate(-50%, -50%);
    box-shadow: 0 0 0 11px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.9) translate(-50%, -50%);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.spotlight-container {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 355px;
  height: 100%;
  padding: 83px;
}

.spotlight {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px;
  filter: blur(5px);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.open {
  opacity: 1;
  animation: spotlight-animation 2s infinite alternate;
}

.closed {
  opacity: 0;
}

@keyframes spotlight-animation {
  0% {
    transform: scale(0.5);
    background-color: rgb(212, 102, 102);
  }

  30% {
    transform: scale(0.3);
    background-color: rgb(51, 194, 86);
  }

  50% {
    transform: scale(1.2);
    background-color: rgb(250, 250, 250);
  }

  100% {
    transform: scale(0.5);
    background-color: rgb(218, 221, 49);
  }
}

.sanjmvd {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.dancepole {
  position: relative;
  left: -102px;
  align-content: center;
  top: 58px;
  border: 1px solid #f8c726;
  border-radius: 130px;
  width: 138px;
  height: 229px;
}

.modal-Success {
  --background: linear-gradient(to top, #a8ff78, #78ffd6);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-Error {
  --background: linear-gradient(135deg, #ff8b8b 0%, #ff0000 100%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Top3Title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #343a40;
}

.Top3Content {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: flex-end;
}
.mesd {
  background: var(--mesd) !important;
  border: 2px solid #f57c00;
}
.user-card3T {
  display: flex;
  align-items: center;
  background: var(--ion-card-background);
  width: 150px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 200px;
  justify-content: flex-end;
}

.user-card3Ttop1 {
  width: 250px;
  display: flex;
  border: 2px solid gold;
  /* height: 78px; */
  background: var(--ion-card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 26px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-card3Ttop1 img {
  border-radius: 50%;
  width: 100px; /* Larger image for top user */
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

.user-card3T img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 10px;
}

.user-info3T {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.user-card {
  display: flex;
  align-items: center;
  background: var(--ion-card-background);
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 10px;
  gap: 10px;
}

.sdasx {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.user-card img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 15px;
}

.card-container {
  perspective: 1000px;
  cursor: pointer;
  width: 100%; /* Ensure the container takes the full width */
  /* height: 200px; Set a fixed height for the card */
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow for depth */
}

.card-container.flipped .card {
  transform: rotateY(
    180deg
  ); /* Flip the card when the 'flipped' class is applied */
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0;
  backface-visibility: hidden;
  border-radius: 10px;
}

.card-front {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ion-card-background);
  border-radius: 8px;
  z-index: 2;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box; /* Ensure padding doesn't affect the size */
}

.card-back {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transform: rotateY(180deg);
  border-radius: 8px;
  z-index: 1;
  /* padding: 1px; */
  box-sizing: border-box;
  flex-direction: column;
}

.user-info {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.RankedUsersContainer {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}

.user-info p {
  margin: 0;
}

.user-info .username {
  font-weight: bold;
  font-size: 18px;
}

.user-info .surename {
  font-size: 16px;
}

.user-info .reservations {
  font-size: 14px;
}

.trs-cont {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  gap: 24px;
}
.trs {
  font-size: 20px;
  padding: 10px;
  height: 100%;
  width: 100%;
  /* color:var(--ion-color-light-blue); */
  border: none;
}
.trss {
  font-size: 30px;
  padding: 10px;
  width: 100%;
  height: 100%;
  /* border: none; */
  overflow: auto; /* Add this line to enable scrolling */
}

.alert-button.alert-radio-icon.sc-ion-alert-md {
  color: var(--ion-color-light-blue) !important;
  border-color: var(--ion-color-light-blue) !important ;
}

.button-group {
  display: flex;
  /* justify-content: space-between; */
  /* gap: 10px; */
  margin-top: 20px;
}

.closeupImageBorder {
  mask-image: -webkit-radial-gradient(white, black);
  position: absolute;
  background: radial-gradient(circle, #0074e8 8%, #fff 48%);
  background-size: 200% 200%;
  background-repeat: no-repeat;
  border-radius: '20px';
  animation: borderAnimation 3.2s linear infinite;
}

@keyframes borderAnimation {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
.Storie {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
}

.no-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  background-color: #000;
  font-size: 24px;
  text-align: center;
}

.loading-container,
.no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  font-size: 24px;
}
/* Blurred background when the story ends */
.blurred-background {
  filter: blur(8px);
  transition: filter 0.5s ease-in-out;
}

/* Overlay container to display in front of the stories */
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgb(0 0 0 / 93%);
  flex-direction: column;
}

/* Styling for the button that will take the user to the dashboard */
.go-dashboard-button {
  font-size: 18px;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  z-index: 1001;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.blink-animation {
  animation: blink 1s linear infinite; /* Blinks once per second */
  stroke: #8a2be2; /* Use a purple shade for blinking */
}

.status-chip {
  font-size: 0.9rem;
  padding: 0 12px;
  border-radius: 16px;
  height: 40px;
  margin: 8px 0;
}

.pending-chip {
  background-color: #fff3cd !important;
  color: #856404 !important;
}

.complete-chip {
  background-color: #d4edda !important;
  color: #155724 !important;
}

.rejected-chip {
  background-color: #f8d7da !important;
  color: #721c24 !important;
}

.ended-chip {
  background-color: #d1ecf1 !important;
  color: #0c5460 !important;
}

.cancelled-chip {
  background-color: #e2e3e5 !important;
  color: #6c757d !important;
}

.unknown-chip {
  background-color: #ffeeba !important;
  color: #856404 !important;
}
